/**
 * src/components/Footer/index.jsx
 * Footer message and public links
 *
 * created by Lynchee on 7/16/23
 */

import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import './style.css';

const Footer = () => (
  <footer>
    <div className='rounded-social-buttons'>
      <a
        className='social-button facebook'
        href='https://www.facebook.com/profile.php?id=100092435600745'
        target='_blank'
        rel='noreferrer'
      >
        <FaFacebook />
      </a>
      <a
        className='social-button instagram'
        href='https://www.instagram.com/careerscoops/'
        target='_blank'
        rel='noreferrer'
      >
        <FaInstagram />
      </a>
      <a
        className='social-button tiktok'
        href='https://www.tiktok.com/@careerscoops'
        target='_blank'
        rel='noreferrer'
      >
        <FaTiktok />
      </a>
    </div>
    <p className='copyright'>
      Copyright © 2024 Career Scoops. All rights reserved. Career Scoops Career
      Buddy statements may not always be accurate. Ver:{' 101'}
      {process.env.REACT_APP_BUILD_NUMBER}
    </p>
  </footer>
);

export default Footer;
